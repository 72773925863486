import React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"
import Navbar from "../components/navbar"
import Contact from "../components/contact"
import Footer from "../components/footer"

const Success = () => (
  <div>
    <Seo title="Email Sent" />
    <Navbar />
    <main className="main">
      <div className="success__container bd-container">
        <div className="success__box">
          <h1 className="success__title">Thank You!</h1>
          <p className="success__description">
            We've received your message and we'll get back to you within 24
            hours.
          </p>
          <Link to="/" className="button">
            Okay
          </Link>
        </div>
      </div>
      <Contact />
      <Footer />
    </main>
  </div>
)

export default Success
